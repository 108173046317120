<template>
  <div class="majoitus">
    <Hero :img="img" :title="title" />
    <div class="yhteystiedot">
      <div class="yhteystiedot__text" v-html="yhteystiedotText"></div>
      <div class="yhteystiedot__lomake">
        <h2>{{ formHeader }}</h2>
        <form
          name="contact"
          id="contactForm"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          @submit.prevent="handleSubmit"
        >
          <p>
            <label>
              Nimi:
              <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Puhelinumero:
              <input type="text" name="tel" />
            </label>
          </p>
          <p>
            <label>
              Sähköposti:
              <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Viesti:
              <textarea name="message"></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Lähetä</button>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";

export default {
  name: "Majoitus",
  components: {
    Hero
  },
  data() {
    return {
      title: "Yhteystiedot",
      img: {
        name: "yhteystiedot.jpg",
        alt: "Isäntä"
      },
      yhteystiedotImg: {
        name: "marjasuvannon-lomamajat.jpg",
        alt: "Maisemat joella"
      },
      yhteystiedotText:
        "<h2>Marjasuvannon lomamajat</h2><p>Savukoskelta 15 kilometriä Kemijärven suuntaan, keskellä kauniita maisemia, erämaan rauhallisessa hiljaisuudessa, puhtaiden vesien äärellä sijaitsee Marjasuvannon lomamajat.</p><p>Palveluitamme voit tiedustella puhelimitse tai sähköpostilla. Soita koska vaan!</p><p>Mikko ja Johanna Kilpimaa<br><a href='tel:+358400300959'>0400 300 959</a><br><a href='mailto:mikko.kilpimaa@gmail.com'>mikko.kilpimaa@gmail.com</a></p>",

      formHeader: "Ota yhteyttä"
    };
  },
  methods: {
    handleSubmit(e) {
      const formi = new FormData(e.target);
      const formiData = {
        name: formi.get("name"),
        tel: formi.get("tel"),
        email: formi.get("email"),
        message: formi.get("message")
      };
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": "contact",
          ...formiData
        })
      }).then(() => {
        location.reload();
      });
    },
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    }
  }
};
</script>

<style scoped lang="stylus">
@import '../styles/variables.styl';

.yhteystiedot {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: containers.medium;
  max-width: 96%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  margin: 50px auto;

  &__text {
    flex-basis: 49%;
    width: 49%;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 768px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0 0 20px;
    }

    >>> a {
      color: colors.black;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      text-decoration: none;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: -4px;
        width: calc(100% + 8px);
        height: 2px;
        background-color: colors.black;
        opacity: 0;
        transition: all 300ms ease-in;
      }

      &:hover {
        &:after {
          transition: all 300ms ease-out;
          opacity: 1;
          bottom: -4px;
        }
      }
    }
  }

  &__lomake {
    flex-basis: 49%;
    width: 49%;
    padding: 20px 30px;
    border: 1px solid colors.black;
    box-sizing: border-box;

    @media (max-width: 768px) {
      flex-basis: 100%;
      width: 100%;
    }

    h2 {
      margin-top: 0;
    }

    form {
      p {
        margin: 0 0 12px;
        text-align: left;
        overflow: hidden;
      }

      label {
        font-family: fonts.sans;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }

      input, textarea {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 5px;
        border: 1px solid colors.black;
        font-family: fonts.sans;
        font-size: 16px;
        line-height: 22px;
        margin-top: 4px;
      }

      textarea {
        height: 120px;
      }

      button {
        display: block;
        margin: 0 0 0 auto;
        padding: 12px 32px;
        font-family: fonts.sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        appearance: none;
        // border: 2px solid colors.black;
        // color: colors.black;
        cursor: pointer;
        color: colors.white;
        font-weight: 700;
        text-transform: uppercase;
        background-color: colors.orange;
        border: 2px solid colors.orange;
        border-radius: 3px;
        transition: all 300ms ease-in;

        &:hover {
          transition: all 300ms ease-out;
          background-color: colors.white;
          color: colors.orange;
        }
      }
    }
  }
}
</style>
